<script>
</script>

<section class="hero has-background-black is-dark">
    <div class="hero-body">
        <p class="title">Python Web Development with <em>Sanic</em></p>
        <p class="subtitle">
            Using a next generation framework to improve your skills and the
            speed and scalability of web apps
        </p>
        <p class="subtitle">
            By, <strong>
                <a href="https://github.com/ahopkins" target="_blank">
                    Adam Hopkins
                </a>
            </strong>
        </p>
    </div>
</section>

<style>
    em {
        font-style: normal;
        color: #ff0d68;
    }
    a {
        text-decoration: underline;
    }
</style>
