<script>
    export let baseURL;
</script>

<section class="section has-background-grey-darker">
    <div class="container">
        <div class="columns is-centered">
            <div class="column is-two-thirds-tablet is-half-desktop">
                <h3 class="is-size-3">About this book</h3>
                <p>
                    Today’s developers need something more powerful and
                    customizable when it comes to web app development. They
                    require effective tools to build something unique to meet
                    their specific needs, and not simply glue a bunch of things
                    together built by others. This is where Sanic comes into the
                    picture. Built to be unopinionated and scalable, Sanic is a
                    next-generation Python framework and server tuned for high
                    performance.
                </p>
                <p>
                    This Sanic guide starts by helping you understand Sanic’s
                    purpose, significance, and use cases. You’ll learn how to
                    spot different issues when building web applications, and
                    how to choose, create, and adapt the right solution to meet
                    your requirements. As you progress, you’ll understand how to
                    use listeners, middleware, and background tasks to customize
                    your application. The book will also take you through
                    real-world examples, so you will walk away with practical
                    knowledge and not just code snippets.
                </p>
                <p>
                    By the end of this web development book, you’ll have gained
                    the knowledge you need to design, build, and deploy
                    high-performance, scalable, and maintainable web
                    applications with the Sanic framework.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="section has-background-black-bis">
    <div class="container">
        <div class="columns is-centered">
            <div class="column is-two-thirds-tablet is-half-desktop">
                <h3 class="is-size-3">About this website</h3>
                <p>
                    This website is the web application from <a
                        href="https://github.com/PacktPublishing/Python-Web-Development-with-Sanic/tree/main/Chapter11"
                        >Chapter 11, <em>A Complete Real-World Example</em> of the
                        book.</a
                    >
                </p>
                <p>
                    You are invited to play around with the application by
                    logging in with GitHub, interacting with the API, and
                    reviewing the source code.
                </p>
                <ul>
                    <li>
                        API Docs: <a href="{baseURL}/docs">{baseURL}/docs</a>
                    </li>
                    <li>
                        Deployed source code: <a
                            href="https://github.com/ahopkins/sanicbook"
                            >ahopkins/sanicbook</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<style>
    p {
        margin-bottom: 1em;
    }

    a {
        text-decoration: underline;
        color: #fff;
    }
    a:hover {
        text-decoration: none;
        color: #fff;
    }
</style>
