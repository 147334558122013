<section class="section">
    <nav class="level">
        <div class="level-item has-text-centered">
            <div>
                <p class="heading">Source code</p>
                <p class="title">
                    <a
                        href="https://github.com/PacktPublishing/Web-Development-with-Sanic"
                        target="_blank"
                    >
                        GitHub
                    </a>
                </p>
            </div>
        </div>
        <div class="level-item has-text-centered">
            <div>
                <p class="heading">Buy now</p>
                <p class="title">
                    <a
                        href="https://www.amazon.com/dp/1801814414"
                        target="_blank"
                    >
                        Amazon
                    </a>
                </p>
            </div>
        </div>
        <div class="level-item has-text-centered">
            <div>
                <p class="heading">Publisher</p>
                <p class="title">
                    <a
                        href="https://www.packtpub.com/product/python-web-development-with-sanic/9781801814416"
                        target="_blank"
                    >
                        Packt Publishers
                    </a>
                </p>
            </div>
        </div>
    </nav>
</section>

<style>
    section {
        background-color: #ff0d68;
        color: #000;
    }
    a {
        text-decoration: underline;
        color: #000;
    }
    a:hover {
        text-decoration: none;
        color: #000;
    }
</style>
